
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        




















































.add-users {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.add-users__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 1rem 0;
}

.add-users__input {
  flex-grow: 1;
  margin-top: 0.5rem;
  padding: 1rem;
}

.add-users__footer {
  margin-top: auto;
}
